import ProductListPage from 'Component/ProductListPage';
import SourceProductList from 'SourceComponent/ProductList/ProductList.component';
import { PageProps } from 'SourceComponent/ProductList/ProductList.type';
import { ReactElement } from 'Type/Common.type';

export * from 'SourceComponent/ProductList/ProductList.component';

/** @namespace Pwa/Component/ProductList/Component */
export class ProductListComponent extends SourceProductList {
    renderPage(props: Partial<PageProps> = {}): ReactElement {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            isLoading,
            isVisible,
            mix,
            isPlp,
        } = this.props;
        const {
            items = [],
            // keys = [],
            pageNumber = 0,
            selectedFilters = {},
            wrapperRef,
            key,
        } = this._processProps(props);

        return (
            <ProductListPage
              key={ key }
              isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
              updatePages={ loadPage }
              isLoading={ isLoading }
              isVisible={ isVisible }
              mix={ mix }
              items={ items }
              pageNumber={ pageNumber }
              selectedFilters={ selectedFilters }
              wrapperRef={ wrapperRef }
              isPlp={ isPlp }
            />
        );
    }
}

export default ProductListComponent;
