import SourceProductListPage from 'SourceComponent/ProductListPage/ProductListPage.component';
import { ReactElement } from 'Type/Common.type';

export * from 'SourceComponent/ProductListPage/ProductListPage.component';
/** @namespace Pwa/Component/ProductListPage/Component */
export class ProductListPageComponent extends SourceProductListPage {
    renderItems(): ReactElement {
        const { items, isLoading } = this.props;

        if (!items.length || isLoading) {
            return this.renderPlaceholderItems();
        }

        // eslint-disable-next-line
        // console.log('test2');

        return this.renderPageItems();
    }
}

export default ProductListPageComponent;
